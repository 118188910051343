import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public lat: number = 28.645788;
  public lng: number = -106.1210978;
  public zoom: number = 19;

  constructor() { }

  ngOnInit() { }

  subscribe(){ }

}