import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '../core/http.service';
import { HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private API = {
    URL_USERS: environment.backUrl+`users`
  };

  constructor(private httpService: HttpService, private http: HttpClient) { }

  public guestLogin() {
    let observable = new Observable();
    observable = this.httpService.sendRequest(this.API.URL_USERS + '/guest_login', 'GET', '');

    return observable;
  }

}
