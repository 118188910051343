import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../orders.component';

@Component({
  selector: 'app-order-dialog',
  templateUrl: './order-dialog.component.html',
  styleUrls: ['./order-dialog.component.scss']
})
export class OrderDialogComponent {
  constructor(public dialogRef: MatDialogRef<OrderDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData,) {}
      
      onNoClick(): void {
          this.dialogRef.close();
        }

        onSelectVariantType(valor){
          this.dialogRef.close(valor);
        }
}
