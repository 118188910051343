<mat-sidenav-container>

    <mat-sidenav [opened]="false" mode="over" #sidenav class="sidenav mat-elevation-z6">
        <button mat-icon-button color="warn" class="close" (click)="sidenav.close()">
            <mat-icon color="warn">close</mat-icon>
        </button>
        <div class="divider"></div>
        <app-sidenav-menu [menuItems]="sidenavMenuItems" [menuParentId]="0"></app-sidenav-menu>
        <div class="divider"></div>
        <div class="text-center py-2">
            <a href="https://www.facebook.com/profile.php?id=100075686328256">
                <img class="social-icon" [src]="'assets/images/others/facebook_icon.png'" alt="">
            </a>
            <a href="https://www.instagram.com/tienda_/">
                <img class="social-icon" [src]="'assets/images/others/instagram_icon.png'" alt="">
            </a>
        </div>
    </mat-sidenav>


    <mat-toolbar color="primary" id="header-toolbar">
        <mat-toolbar-row fxLayoutAlign="space-between center" class="top-toolbar theme-container">
            <span fxHide="false" fxHide.gt-sm>
                <button mat-button (click)="sidenav.toggle()" class="sidenav-toggle">
                    <mat-icon>menu</mat-icon>
                </button>
            </span>
            <span fxShow="false" fxShow.gt-xs fxLayoutAlign="center center">
                <mat-icon class="mat-icon-sm">call</mat-icon> <a class="phone_link" href="tel:+5216141495020">614 149
                    5020</a>
            </span>
            <span fxShow="false" fxShow.gt-sm>{{ 'WELCOME' | translate }}</span>
            <app-top-menu></app-top-menu>
        </mat-toolbar-row>
        <mat-toolbar-row fxLayoutAlign="space-between center" class="logo-toolbar theme-container">

            <div fxFlex="20">
                <a class="logo" routerLink="/" (click)="closeSubMenus()">
                    <img [src]="'assets/images/brands/logo/tienda_B.png'" alt="">
                    <!--Tú tienda-->
                </a>
            </div>
            <div fxFlex fxShow="false" fxShow.gt-sm>
                <form method="get" class="search-form" fxLayout="row" [formGroup]="form">
                    <!-- <button mat-raised-button [matMenuTriggerFor]="categoriesMenu" #categoriesMenuTrigger="matMenuTrigger" type="button" class="mat-elevation-z0 categories text-truncate text-muted">{{category?.name}}<mat-icon>arrow_drop_down</mat-icon></button>
                    <mat-menu #categoriesMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="categories-dropdown">
                        <span (mouseleave)="categoriesMenuTrigger.closeMenu()">
                            <app-category-list [categories]="categories" [categoryParentId]="0" (change)="changeCategory($event)"></app-category-list>
                        </span>
                    </mat-menu> -->
                    <input type="text" placeholder="Introduce tu búsqueda" formControlName="search_value" fxFlex
                        (keyup)="search()">
                    <button mat-mini-fab (click)="search()" type="button"
                        class="search-btn mat-elevation-z0 text-muted">
                        <mat-icon>search</mat-icon>
                    </button>
                </form>
            </div>
            <div fxFlex="20" fxLayout="row" fxLayoutAlign="end center">
                <div fxLayoutAlign="center center" [@.disabled]="true">
                    <!-- <button mat-button [matMenuTriggerFor]="cartMenu" #cartMenuTrigger="matMenuTrigger"
                        class="flex-row-button" (click)="updateCart()"> -->
                        <button mat-button class="flex-row-button" (click)="openCart()" matTooltip="Ver carrito">
                        <mat-icon class="mat-icon-lg">shopping_cart</mat-icon>
                        <span *ngIf="appService.Data.totalCartCount > 0"
                            class="cart-items-count">{{appService.Data.totalCartCount}}</span>
                        <div fxLayout="column" fxLayoutAlign="center center" fxShow="false" fxShow.gt-xs
                            class="top-cart">
                            <span>{{ 'MSG_CART' | translate }}</span>
                            <span *ngIf="appService.Data.totalCartCount > 0">
                                <bdi>{{appService.Data.totalCartCount}} item<span
                                        *ngIf="appService.Data.totalCartCount > 1">s</span> -
                                    $<span>{{appService.Data.totalPrice | number : '1.2-2'}}</span>
                                </bdi>
                            </span>
                        </div>
                        <!-- <mat-icon class="mat-icon-sm">arrow_drop_down</mat-icon> -->
                    </button>
                    <!-- <mat-menu #cartMenu="matMenu" [overlapTrigger]="false" xPosition="before"
                        class="top-menu-dropdown cart-dropdown">
                        <ng-template matMenuContent>
                            <span (mouseleave)="cartMenuTrigger.closeMenu()" class="d-block">
                                <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted">
                                    <b><span>{{appService.Data.totalCartCount}} {{ 'CART.ITEM' | translate}}<span
                                                *ngIf="appService.Data.totalCartCount > 1">S</span></span></b>
                                    <b><a [routerLink]="['/cart']" class="text-muted">{{ 'CART.VIEW_CART_BTN' |
                                            translate}}</a></b>
                                </div>
                                <div class="divider mt-1"></div>
                                <div *ngIf="appService.Data.totalCartCount == 0" class="py-1 text-muted text-center">{{
                                    'CART.EMPTY_MSG_CART' | translate}}</div>
                                <mat-list>
                                    <mat-list-item *ngFor="let product of appService.Data.cartList" class="listProduct">
                                        <p>{{product.quantity}}</p>
                                        <h3 matLine class="text-muted cart_product_name"> {{product.name}}
                                            <div *ngFor="let variant of product?.variants">
                                                <div *ngIf="variant.type === 'color'" class="color text-muted">
                                                    <span class="fw-500">{{variant.label}}:</span>
                                                    <span class="cart-variant">
                                                        <span>
                                                            <div class="color_variant"
                                                                [style.background]="variant.values.label"></div>
                                                        </span>
                                                    </span>
                                                </div>

                                                <div *ngIf="variant.type === 'radiobutton'" class="size text-muted">
                                                    <span class="fw-500"> {{variant.label}}:</span>
                                                    <span class="cart-variant">
                                                        <span>{{variant.values.label}}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </h3>
                                        <img [src]="product.images[0].small" alt="image">
                                        <button mat-icon-button color="warn" class="remove"
                                            (click)="remove(product);stopClickPropagate($event)"
                                            matTooltip="{{ 'BTN.REMOVE' | translate}}" matTooltipPosition="before">
                                            <mat-icon class="mat-icon-sm">close</mat-icon>
                                        </button>
                                    </mat-list-item>
                                </mat-list>
                                <div *ngIf="appService.Data.cartList.length > 0" class="cart-dropdown-footer">
                                    <div class="divider mt-1"></div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted mt-1">
                                        <b>{{ 'CART.SUBTOTAL' | translate}}:</b>
                                        <b class="new-price">${{ grandTotal | number : '1.2-2'}}</b>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted">
                                        <b *ngIf="appService.Data.order.discountAmount !== 0" class="text-muted">{{ 'CART.DISCOUNT' | translate}}*:</b>
                                        <b *ngIf="appService.Data.order.discountAmount !== 0" class="text-muted">${{ appService.Data.order.discountAmount | number : '1.2-2'}}</b>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted">
                                        <b *ngIf="appService.Data.isFreeShipping">{{ 'CART.SHIPING' | translate}}:</b>
                                        <b *ngIf="appService.Data.isFreeShipping" >{{ 'CART.FREE' | translate}}</b> 
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted">
                                        <b>{{ 'CART.GRAND_TOTAL' | translate}}:</b>
                                        <b class="new-price" > ${{ (grandTotal - appService.Data.order.discountAmount) | number : '1.2-2'}}</b> 
                                    </div>
                                    <span *ngIf="appService.Data.order.discountAmount !== 0" class="text-muted">*Descuentos aplicados: {{appService.Data.order.coupons}}</span>
                                    <div class="divider mt-1"></div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-1">
                                        <button mat-mini-fab color="warn" (click)="clear();stopClickPropagate($event)"
                                            matTooltip="{{ 'BTN.CLEAR_ALL' | translate}}" matTooltipPosition="after">
                                            <mat-icon>remove_shopping_cart</mat-icon>
                                        </button>
                                        <a (click)="openCart()" mat-mini-fab color="primary"
                                            matTooltip="{{ 'BTN.CHECKOUT' | translate}}" matTooltipPosition="before">
                                            <mat-icon>check</mat-icon>
                                        </a>
                                    </div>
                                </div>
                            </span>
                        </ng-template>
                    </mat-menu>  -->
                </div>

                <!-- <div class="progress">
                    <div class="progress-bar onehundred" [style.width.%]="percentage"></div>
                </div> -->
                <!-- <div fxLayout="column" fxLayoutAlign="center center"   class="top-cart">
                    <span>Envío gratis {{appService.Data.shippingPercentage | number : '1.2-2'}} %</span>
                    <div class="progress">
                    <div class="progress-bar onehundred" [style.width.%]="appService.Data.shippingPercentage"></div>
                    </div>
                </div> -->

                <div fxLayoutAlign="center center" fxHide="false" fxHide.gt-sm [@.disabled]="true">
                    <button mat-icon-button [matMenuTriggerFor]="searchMenu" #searchMenuTrigger="matMenuTrigger"
                        class="search-toggle-btn">
                        <mat-icon class="mat-icon-lg">search</mat-icon>
                    </button>
                    <mat-menu #searchMenu="matMenu" [overlapTrigger]="false" xPosition="before"
                        class="search-dropdown mat-elevation-z8">
                        <ng-template matMenuContent>
                            <form method="get" fxFlex class="search-form" [formGroup]="form">
                                <!-- <button mat-raised-button [matMenuTriggerFor]="categories2Menu" #categories2MenuTrigger="matMenuTrigger" type="button" class="mat-elevation-z0 categories text-muted" (click)="stopClickPropagate($event)">{{category?.name}}<mat-icon>arrow_drop_down</mat-icon></button> -->
                                <!-- <mat-menu #categories2Menu="matMenu" [overlapTrigger]="false" xPosition="before" class="categories-dropdown">
                                    <ng-template matMenuContent>
                                        <span (mouseleave)="categories2MenuTrigger.closeMenu()">
                                            <app-category-list [categories]="categories" [categoryParentId]="0" (change)="changeCategory($event)"></app-category-list>
                                        </span>
                                    </ng-template>
                                </mat-menu> -->
                                <input type="text" placeholder="Introduce tu búsqueda" formControlName="search_value"
                                    fxFlex (click)="stopClickPropagate($event)" (keyup)="search()">
                                <button mat-mini-fab (click)="search()" type="button"
                                    class="search-btn mat-elevation-z0 text-muted">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </form>
                        </ng-template>
                    </mat-menu>
                </div>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
    <mat-toolbar color="primary" id="header-toolbar">
        <div fxLayout="column" fxLayoutAlign="center center" class="top-cart envio" *ngIf="appService.Data.freeShippingAvailable">
            <span *ngIf="!appService.Data.isFreeShipping">
                <mat-icon class="mat-icon-sm">local_shipping</mat-icon> Te faltan
                ${{appService.Data.remainingShippingAmount | number : '1.2-2'}} para que tu envío sea gratis.
            </span>
            <!-- {{appService.Data.isFreeShipping}} -->
            <span *ngIf="appService.Data.isFreeShipping">
                <mat-icon class="mat-icon-sm">local_shipping</mat-icon> Tu envío es gratis.
            </span>
            <div class="progress">
                <div [ngClass]="[appService.Data.shippingPercentage <= 25 ? 'zero' : '', 
                    (appService.Data.shippingPercentage > 25 && appService.Data.shippingPercentage <= 50) ? 'thirty' : '', 
                    (appService.Data.shippingPercentage > 50 && appService.Data.shippingPercentage <= 75) ? 'fifty' : '',  
                    (appService.Data.shippingPercentage > 75 && appService.Data.shippingPercentage <= 99) ? 'eighty' : '',
                    appService.Data.shippingPercentage == 100 ? 'onehundred' : '']"
                    class="progress-bar" [style.width.%]="appService.Data.shippingPercentage"></div>
            </div>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center" class="top-cart envio" *ngIf="!appService.Data.freeShippingAvailable">
            <span>
                <mat-icon class="mat-icon-sm">local_shipping</mat-icon>Alguno de tus productos no aplica para que tu envío sea gratis.
            </span>
        </div>
    </mat-toolbar>



    <app-menu></app-menu>


    <div class="theme-container main">
        <app-breadcrumb></app-breadcrumb>
        <router-outlet></router-outlet>
    </div>


    <!-- <app-options></app-options> -->

    <app-footer></app-footer>

    <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition"
        (click)="scrollToTop()">
        <mat-icon>arrow_upward</mat-icon>
    </div>


</mat-sidenav-container>