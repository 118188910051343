<footer class="bg-primary footer">
    <!--div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="center center" class="border-bottom-mute subscribe-block theme-container">
        <div fxFlex="100" fxFlex.gt-sm="40" fxLayout="column" fxLayoutAlign="center center"> 
            <h1 class="fw-500">Subscribe our Newsletter</h1>
            <h3 class="fw-300 secondary-color">Stay up to date with our latest new and products</h3>
        </div>
        <form method="get" class="subscribe-search-form" fxLayout="row" fxFlex="100" fxFlex.gt-sm="42.4" ngClass.sm="mt-2" ngClass.xs="mt-2">
            <input type="text" placeholder="Your email address..." fxFlex>
            <button mat-raised-button color="accent" (click)="subscribe()" type="button" class="mat-elevation-z0 text-muted">Subscribe</button>
        </form>
    </div-->
    <div fxLayout="row wrap" fxLayoutAlign="space-between" class="py-3 border-bottom-mute theme-container">        
        <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="40"> 
            <h3 class="col-title">{{ 'SITE_MAP.TITLE' | translate }}</h3>    
            <!--p class="mt-2"><a href="javascript:void(0);" class="link secondary-color">My Account</a></p-->
            <p class="mt-1"><a target="_blank" href="https://api.whatsapp.com/send?phone=+526141495020&text=¡Hola! Estoy interesado en sus productos, necesito ayuda." class="link secondary-color">Contacto</a></p>
            <p class="mt-1"><a routerLink="/products" class="link secondary-color">Productos</a></p>
            <!--p class="mt-1"><a routerLink="/wishlist" class="link secondary-color">Wishlist</a></p-->
            <p class="mt-1"><a routerLink="/cart" class="link secondary-color">Carrito de compras</a></p>
            <p class="mt-1"><a href="/faqs" class="link secondary-color">Preguntas frecuentes</a></p>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="60" ngClass.xs="mt-2" class="contact-information"> 
            <h3 class="col-title">{{ 'CONTACT.TITLE' | translate }}</h3>            
            <!-- <p fxLayout="row" fxLayoutAlign="start center" class="mt-2 secondary-color">
                <mat-icon>location_on</mat-icon>
                <span>2903 Avenue Z, Brooklyn, NY</span>
            </p> -->
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>call</mat-icon>
                <span><a _ngcontent-serverapp-c360="" href="tel:+5216141495020" class="phone_link">{{ 'CONTACT.PHONE' | translate }}</a></span>
            </p>
            <a href="mailto:{{ 'CONTACT.EMAIL' | translate }}">
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                    <mat-icon>mail_outline</mat-icon>
                    <span>{{ 'CONTACT.EMAIL' | translate }}</span>
                </p>
            </a>
            
           <!--  <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>schedule</mat-icon>
                <span>Lunes - Domingo / 9:00AM - 8:00PM</span>
            </p> -->
            <!-- <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>directions</mat-icon>
                <a href="javascript:void(0);" class="link secondary-color">Get directions</a>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>directions_bus</mat-icon>
                <span>Routes to us</span>
            </p> -->
        </div>
        <div fxFlex="100" fxFlex.gt-sm="50" ngClass.sm="mt-2" ngClass.xs="mt-2"> 
            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [mapTypeControl]="true">
                <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="true"></agm-marker>
            </agm-map>
        </div>
    </div>
    <!--div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="copyright secondary-color theme-container">
        <p ngClass.xs="mt-1">Copyright © 2018 All Rights Reserved</p>
        <p>Designed & Developed by <a mat-button href="http://themeseason.com/" target="_blank">ThemeSeason</a></p>
    </div-->
</footer>