<h2 mat-dialog-title>Detalle de la orden</h2>
<div mat-dialog-content>
  <div *ngIf="data.order.deliveryInfo">
    <h4>Datos de entrega</h4>
    <div fxFlex="100" fxFlex.gt-sm="33.33" class="px-1">
      {{data.order.deliveryInfo.name}} {{data.order.deliveryInfo.lastName1}} {{data.order.deliveryInfo.lastName2}}
    </div>
    
  </div>
  <div  >
    <h4>Productos</h4>
    <mat-divider></mat-divider>
    <div *ngIf="data.products.length > 0" class="mat-table review-order-table">
      <!-- <div fxShow="false" fxShow.gt-xs class="mat-header-row desktop">
            <div class="mat-header-cell">{{ 'CART.PRODUCT' | translate}}</div>
            <div class="mat-header-cell">{{ 'CART.NAME' | translate}}</div>
            <div class="mat-header-cell">{{ 'CART.VARIANT' | translate}}</div>
            <div class="mat-header-cell">{{ 'CART.PRICE' | translate}}</div>
            <div class="mat-header-cell">{{ 'CART.QUANTITY' | translate}}</div>
            <div class="mat-header-cell">{{ 'CART.TOTAL' | translate}}</div>
          </div> -->
          <div fxShow="false" fxShow.gt-xs *ngFor="let product of data.products" class="mat-row desktop">
            <!-- PRODUCT -->
            <div class="mat-cell">
              <img [src]="product.images[0].small">
            </div> 
            <!-- NAME -->
            <div class="mat-cell"><a [routerLink]="['/products', product.id, product.name]"
                class="product-name">{{product.name}}</a></div>
                <!-- VARIANT -->
            <div class="mat-cell">
              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="details"
                *ngFor="let variant of product?.variants">
                <div *ngIf="variant.type === 'color'" class="color">
                  <span class="fw-500">{{variant.label}}:</span>
                  <span class="cart-variant">
                    <span><div class="color_variant" [style.background]="variant.values.label"></div></span>
                  </span>
                </div>
      
                <div *ngIf="variant.type === 'radiobutton'" class="size">
                  <span class="fw-500"> {{variant.label}}:</span>
                  <span class="cart-variant">
                    <span>{{variant.values.label}}</span>
                  </span>
                </div>
      
              </div>
            </div>
            <!-- QUANTITY -->
            <div class="mat-cell">{{product.quantity}}</div>
            <!-- TOTAL -->
            <div class="mat-cell">
              <span *ngIf="product.available">
                ${{product.amount | number : '1.2-2'}}
              </span>
            </div>
          </div>
          <!-- Mobile version  -->
          <div fxHide="false" fxHide.gt-sm *ngFor="let product of data.products" class="mat-row">
            <div class="mat-cell">
              <!-- <img [src]="product.images[0].small"> -->
              <div>
                <a class="product-name">{{ 'CART.PRODUCT' | translate}}: 
                  <span class="text-muted">{{product.name}}</span>
                </a>
              </div>
              <div class="product-name">
                <span>{{ 'CART.VARIANT' | translate}}:</span>
              </div>
              <div fxLayoutAlign="space-between center" class="details text-muted"
                *ngFor="let variant of product?.variants">
                <div *ngIf="variant.type === 'color'" class="color">
                  <span class="fw-500">{{variant.label}}:</span>
                  <span class="cart-variant">
                    <span><div class="color_variant" [style.background]="variant.values.label"></div></span>
                  </span>
                </div>
      
                <div *ngIf="variant.type === 'radiobutton'" class="size">
                  <span class="fw-500"> {{variant.label}}:</span>
                  <span class="cart-variant">
                    <span>{{variant.values.label}}</span>
                  </span>
                </div>
      
              </div>
              <!-- QUANTITY -->
              <div class="product-name">
                {{ 'CART.QUANTITY' | translate}}:
                <span *ngIf="product.available">
                  <app-controls [product]="product" [type]="'wish'" (onQuantityChange)="updateCart($event)"></app-controls>
                </span>
                <span *ngIf="!product.available">
                  No hay disponibles
                </span>
              </div>
              <!-- TOTAL -->
              <div class="product-name">
                <span *ngIf="product.available">
                  {{ 'CART.TOTAL' | translate}}: <span class="text-muted">${{product.amount | number : '1.2-2'}} </span>
                </span>
              </div>
      
            </div>
          </div>  
    </div>
    <div *ngIf="data.order.deliveryAddress">
      <h4>Dirección de entrega </h4>
      {{data.order.deliveryAddress.street}} {{data.order.deliveryAddress.numberStreet}}, {{data.order.deliveryAddress.neighborhood}}, 
      {{data.order.deliveryAddress.state}}, {{data.order.deliveryAddress.zipcode}}, {{data.order.deliveryAddress.country}}
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Cancelar</button>
</div>