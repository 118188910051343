import { Component, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AppService } from '../../../app.service';
import { Product, Value, CombinationArray } from '../../../app.models';
// import { ProductsService } from '../../../services/products.service';
import { OrdersService } from '../../../services/orders.service';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductDialogComponent implements OnInit {
  public config: SwiperConfigInterface = {};
  public validVariants: Value[] = [];
  constructor(public appService: AppService,
    public dialogRef: MatDialogRef<ProductDialogComponent>,
    // private productsService: ProductsService,
    public ordersService: OrdersService,
    @Inject(MAT_DIALOG_DATA) public product: Product) { }

  ngOnInit() {
    this.product.variants.forEach(variant => {
      variant.values.forEach(value => {
        value.default = false;
      })
    })

    // setTimeout(()=>{ // this will make the execution after the above boolean has changed
    //   this.searchElement.nativeElement.focus();
    // },0);
  }

  public close(): void {
    this.dialogRef.close();
  }

  clickEvent(idValue, idVariante) {
    var indexVariant = this.product.variants.findIndex(variant => variant._id === idVariante)
    this.product.variants[indexVariant].values.forEach(value => {
      if (value._id === idValue) {
        value.default = true;
        this.validStoke();
      } else {
        value.default = false;
      }
    })
  }

  validStoke() {
    var validVariants = [];
    this.product.variants.forEach(variant => {
      var val = variant.values.find(element => element.default);
      if (val !== undefined) {
        validVariants.push({ variantId: variant._id, value:val._id})
      }
    })

    if (validVariants.length === this.product.variants.length) {
      var variantsCombination = {}

      validVariants.forEach(validVariant => {
        variantsCombination[validVariant.variantId] = validVariant.value
      })

      const params = {
        "product": {
          "productId": this.product._id,
          variantsCombination
        }
      }
      this.ordersService.combination(params).subscribe((data:CombinationArray ) => {
        if (data.combination) {
          this.product.stock = data.combination.stock;
          this.product.price = data.combination.price;
          this.product.oldPrice = data.combination.oldPrice;
        }else{
          this.product.stock = 0;
          this.product.price = 0;
        }
       
      });
    }
  }
}