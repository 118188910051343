import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from './app.settings';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from './services/users.service';
import { OrdersService } from './services/orders.service';
import { AppService } from './app.service';
import { GuestLogin, Ordenes } from './app.models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loading: boolean = false;
  public settings: Settings;
  constructor(public appSettings: AppSettings,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    public translate: TranslateService,
    public usersService: UsersService,
    public ordersService: OrdersService,
    public appService: AppService
  ) {
    this.settings = this.appSettings.settings;
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('es');
    translate.use('es');
  }

  ngOnInit() {
    // this.router.navigate(['']);  //redirect other pages to homepage on browser refresh  
    if (!localStorage.getItem('token') || localStorage.getItem('token') == "undefined") {
      this.getToken();
    } else {
      this.getOrder();
    }

  }
  getToken() {
    this.usersService.guestLogin().subscribe((res: GuestLogin) => {
      const response: GuestLogin = {
        token: res.token
      };
      localStorage.setItem('token', response.token);
      this.getOrder();
    });
  }
  getOrder() {
    if (!localStorage.getItem('token') || localStorage.getItem('token') == "undefined") {
      this.usersService.guestLogin().subscribe((res: GuestLogin) => {
        const response: GuestLogin = {
          token: res.token
        };
        localStorage.setItem('token', response.token);
        console.log("Token:" + localStorage.getItem('token'));
        this.ordersService.getOrdersBytoken().subscribe((res: Ordenes) => {
          if (res.order?.products) {
            this.appService.Data.totalPrice = res.order.amount;
            this.appService.Data.totalCartCount = res.order.quantity;
            this.appService.Data.shippingPercentage = res.order.shippingPercentage;
            this.appService.Data.remainingShippingAmount = res.order.remainingShippingAmount;
            this.appService.Data.isFreeShipping = res.order.isFreeShipping;
            this.appService.Data.freeShippingAvailable = res.order.freeShippingAvailable;
            this.appService.Data.cartList = res.order.products;
          }
          this.appService.Data.order = res.order;
        });
      })
    }else {
      this.ordersService.getOrdersBytoken().subscribe((res: Ordenes) => {
        if (res.order?.products) {
          this.appService.Data.totalPrice = res.order.amount;
          this.appService.Data.totalCartCount = res.order.quantity;
          this.appService.Data.shippingPercentage = res.order.shippingPercentage;
          this.appService.Data.remainingShippingAmount = res.order.remainingShippingAmount;
          this.appService.Data.isFreeShipping = res.order.isFreeShipping;
          this.appService.Data.freeShippingAvailable = res.order.freeShippingAvailable;
          this.appService.Data.cartList = res.order.products;
        }
        this.appService.Data.order = res.order;
      });
    }
  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }
      }
    })
  }
}
