<mat-toolbar id="menu-toolbar" class="top-navbar mat-elevation-z2" fxLayoutAlign="center center">
    <a fxShow.gt-sm mat-button routerLink="/" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'NAV.HOME' | translate }}</a>
    <a fxShow.gt-sm mat-button routerLink="/products" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'NAV.ALL' | translate }}</a>
    <div *ngFor="let category of categories" >
        <a  mat-button [routerLink]="['/products', category.value]" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{category.label}}</a>
    </div>
    
    <a mat-button target="_blank" href="https://api.whatsapp.com/send?phone=+526141495020&text=¡Hola! Estoy interesado en sus productos, necesito ayuda." routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">CONTACTO</a>
    <!-- <a mat-button routerLink="/admin/products/product-list" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">ADMINISTRACIÓN</a> -->

</mat-toolbar>

