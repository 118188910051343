import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { timeout, catchError, map, mergeMap } from 'rxjs/operators';
// import { IResponse } from '../shared/interfaces/response.interface';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private TIMEOUT = 180000;

  constructor(private http: HttpClient) { }

  sendRequest(url: string, method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH' , payload: any, admin?:boolean): Observable<any> {
    let observable: Observable<any> = new Observable<any>();
    // let headers = new HttpHeaders().set('content-type', 'application/json');
    // const headers = new HttpHeaders({'Content-Type':'application/json;', 'Authorization': localStorage.getItem('token')});

    let headers = new HttpHeaders().set('content-type', 'application/json'); // create header object
    if(admin){
      headers = headers.append('Authorization', localStorage.getItem('adminToken')); 
    }else{
      headers = headers.append('Authorization', localStorage.getItem('token')); 
    }
        


    if (method === 'GET') {
      observable = this.http.get(url, { headers}).pipe(
        timeout(this.TIMEOUT),
        catchError(err => of(this.catchError(err)))
      );
    } else if (method === 'POST') {
      observable = this.http.post(url, payload, { headers }).pipe(

        timeout(this.TIMEOUT),
        catchError(err => of(this.catchError(err)))
      );
    }else if (method === 'DELETE') {
      observable = this.http.delete(url, { headers }).pipe(
        timeout(this.TIMEOUT),
        catchError(err => of(this.catchError(err)))
      );
    }else if (method === 'PUT') {
      observable = this.http.put(url, payload, { headers }).pipe(

        timeout(this.TIMEOUT),
        catchError(err => of(this.catchError(err)))
      );
    }else if (method === 'PATCH') {
      observable = this.http.patch(url, payload, { headers }).pipe(

        timeout(this.TIMEOUT),
        catchError(err => of(this.catchError(err)))
      );
    }

    return observable;
  }

  sendImageRequest(url: string, method:  'PUT' , payload: File, imgType:string): Observable<any> {
    let observable: Observable<any> = new Observable<any>();
    let headers = new HttpHeaders().set('Content-Type', 'application/octet-stream'); // create header object

    if (method === 'PUT') {
      observable = this.http.put(url, payload, { headers, observe: 'response'}).pipe(
        map(response => {
            return response;
          }
        ));  
    }
    return observable;
  }
//   private catchError(err: HttpErrorResponse): IResponse {
  private catchError(err: HttpErrorResponse) {
    if(err.status!= 200 && err.error != undefined){
      sessionStorage.setItem("CodeResp",err.status.toString());
      sessionStorage.setItem("ErrorImage",err.error.message);
    }
    const status = err.status ? err.status : 408;
    const statusText = err.statusText ? err.statusText : 'Request Timeout';
    const message = err.statusText ? `${err.name} ${err.message}` : '';
    // console.warn({ codigo: 1, mensaje: 'La operación excedió el tiempo de espera', datos: [{ status, statusText, message }] })
    return { codigo: 1, mensaje: status === 504 ? 'La operación excedió el tiempo de espera' : `Error ${status}--${statusText}`, datos: [{ status, statusText, message }], raw: err};
  }
}
